<template>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <van-loading size="50px" style="color: white;" vertical>
        <span style="font-size: 18px;color: white;">{{$t('common.i_loading')}}</span>
      </van-loading>
    </div>
  </van-overlay>
  <div class="taskCenterLayout">
    <div class="taskCenterBox">
      <div style="text-align: left;">
        <van-image @click="$router.back(-1)" width="30" :src="require('@/assets/images/goBack.png')" />
      </div>
      <div style="margin-top: 2%;">
        <div style="background-color: white;border-radius: 10px;">
          <div v-html="giftCard.remarks" style="white-space: pre-line;text-align: left;padding: 16px 16px 0px 16px;color: rgba(86, 137, 138, 1);font-size: 14px;line-height: 22px;"></div>
          <van-form ref='form' @submit="onSubmit">
            <div style="margin: 1% auto;">
              <div v-for="(form, index) in giftCard.forms" :key="index">
                <van-field v-if="form.keyName == 'account'" v-model="userInfo.account" :label="form.label" :placeholder="form.placeholder" label-align="top" required :rules="account" />
                <van-field v-if="form.keyName == 'accountName'" v-model="userInfo.accountName" :label="form.label" :placeholder="form.placeholder" label-align="top" required :rules="accountName" />
                <van-field v-else-if="form.keyName == 'extraOne'" v-model="userInfo.extraOne" :label="form.label" :placeholder="form.placeholder" label-align="top" required :rules="extraOne" />
                <van-field v-else-if="form.keyName == 'extraTwo'" v-model="userInfo.extraTwo" :label="form.label" :placeholder="form.placeholder" label-align="top" required :rules="extraTwo" />
              </div>
              <div style="text-align: left;font-size: 12px;margin-left: 16px;color: rgba(56, 58, 72, 0.5);margin-top: 2px;">{{$t('convertCenter.i_noResponsibleForWrongAccount')}}</div>
            </div>
            <div style="margin-top: 2%;">
              <div style="text-align: left;margin-left: 16px;">{{$t('convertCenter.i_selectAmount')}}</div>
              <div style="margin: 6px 10px;">
                <div class="RadioStyle">
                  <div class="radioList">
                    <input type="radio" name="Storage" id="Radio1" value="1" v-model="radioParam" />
                    <label for="Radio1">{{giftCard.cash}} {{giftCard.cashUnit}}</label>
                    <!-- <input type="radio" name="Storage" id="Radio2" value="2" v-model="radioParam" />
                    <label for="Radio2">200php</label>
                    <input type="radio" name="Storage" id="Radio3" value="3" v-model="radioParam" />
                    <label for="Radio3">300php</label>
                    <input type="radio" name="Storage" id="Radio4" value="4" v-model="radioParam" />
                    <label for="Radio4">400php</label> -->
                  </div>
                </div>
              </div>
              <div style="margin-top: 20%;padding-bottom: 16px;">
                <van-button native-type="submit" round style="background-color: rgba(67, 115, 251, 1);color: white;height: 38px;vertical-align: middle;width: 220px;position: relative;">
                  <van-image style="vertical-align: middle;width: 44px;position: absolute;top: 2px;left: 46px;" :src="giftCard.giftCardIcon" />
                  <span style="position: absolute;top: 10px;color: white;left: 90px;">{{$t('convertCenter.i_withdrawNow')}}</span>
                </van-button>
                <van-popup v-model:show="showConvert" round style="width: 300px;">
                  <div>
                    <div style="margin-top: 40px;">{{giftCard.cash}} {{giftCard.cashUnit}}</div>
                    <div v-for="(form, index) in giftCard.forms" :key="index" style="text-align: left;margin-left: 20px;font-size: 14px;margin-top: 20px;">
                      <p>{{ form.label }}<span style="font-weight: bold;"> :</span></p>
                      <div style="color: rgba(0, 183, 0, 1);">
                        <p v-if="form.keyName == 'account'">{{ userInfo.account }}</p>
                        <p v-if="form.keyName == 'accountName'">{{ userInfo.accountName }}</p>
                        <p v-if="form.keyName == 'extraOne'">{{ userInfo.extraOne }}</p>
                        <p v-if="form.keyName == 'extraTwo'">{{ userInfo.extraTwo }}</p>
                      </div>
                    </div>
                  </div>
                  <van-button native-type="submit" round color="linear-gradient(to right, rgba(136, 0, 252, 1), rgba(255, 99, 121, 1))" style="width: 160px;height: 36px;margin-top: 30px;margin-bottom: 30px;">
                    {{$t('convertCenter.i_withdrawNow')}}
                  </van-button>
                </van-popup>
              </div>
            </div>
          </van-form>
        </div>
      </div>
    </div>

    <div>
      <van-popup v-model:show="showCenter" round style="width: 240px;height: 250px;">
        <div style="margin-top: 40px;">
          <van-image style="vertical-align: middle;width: 60px;" :src="require('@/assets/images/inReview.png')" />
        </div>
        <div style="margin-top: 20px;">{{$t('convertCenter.i_underReviewPlease')}}</div>
        <router-link to="/convertLog">
          <van-button round color="linear-gradient(to right, rgba(136, 0, 252, 1), rgba(255, 99, 121, 1))" style="width: 160px;height: 36px;margin-top: 40px;">
            {{$t('common.i_ok')}}
          </van-button>
        </router-link>
      </van-popup>
      <van-popup v-model:show="showFail" round style="width: 240px;height: 250px;">
        <div style="margin-top: 40px;">
          <van-image style="vertical-align: middle;width: 60px;" :src="require('@/assets/images/showFail.png')" />
        </div>
        <div style="margin-top: 20px;">{{showFailMsg}}</div>
        <van-button @click="showFail = false" round color="linear-gradient(to right, rgba(136, 0, 252, 1), rgba(255, 99, 121, 1))" style="width: 160px;height: 36px;margin-top: 40px;">
          {{$t('common.i_ok')}}
        </van-button>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  data() {
    const show = ref(false);
    const accountTips = ref("");
    const accountNameTips = ref("");
    const extraOneTips = ref("");
    const extraTwoTips = ref("");
    const checkRegexp = (keyName, value) => {
      var isflag = false;
      this.giftCard.forms.forEach((form) => {
        if (form.keyName == "account") {
          accountTips.value = form.regexpsTips;
        }
        if (form.keyName == "accountName") {
          accountNameTips.value = form.regexpsTips;
        }
        if (form.keyName == "extraOne") {
          extraOneTips.value = form.regexpsTips;
        }
        if (form.keyName == "extraTwo") {
          extraTwoTips.value = form.regexpsTips;
        }
        if (form.keyName == keyName) {
          if (form.regexps != null && !new RegExp(form.regexps).test(value)) {
            isflag = false;
          } else if (value == "" || value == null) {
            isflag = false;
          } else {
            isflag = true;
          }
        }
      });
      return isflag;
    };
    return {
      show,
      radioParam: 1,
      showConvert: false,
      showCenter: false,
      showFail: false,
      showFailMsg: "",
      giftCard: {},
      userInfo: {},
      account: [
        {
          // 自定义校验规则
          validator: (value) => {
            return checkRegexp("account", value);
          },
          message: accountTips,
          trigger: ["onChange", "onBlur"]
        }
      ],
      accountName: [
        {
          // 自定义校验规则
          validator: (value) => {
            return checkRegexp("accountName", value);
          },
          message: accountNameTips,
          trigger: ["onChange", "onBlur"]
        }
      ],
      extraOne: [
        {
          // 自定义校验规则
          validator: (value) => {
            return checkRegexp("extraOne", value);
          },
          message: extraOneTips,
          trigger: ["onChange", "onBlur"]
        }
      ],
      extraTwo: [
        {
          // 自定义校验规则
          validator: (value) => {
            return checkRegexp("extraTwo", value);
          },
          message: extraTwoTips,
          trigger: ["onChange", "onBlur"]
        }
      ]
    };
  },
  methods: {
    getConvertCenter() {
      this.show = true;
      this.$axios({
        url: "/island/web/convertCenter",
        method: "get",
        params: {
          lang: this.$t("common.i_lang")
        }
      }).then((result) => {
        this.show = false;
        this.giftCard = result.data.data.giftCard;
      });
    },
    onSubmit() {
      if (this.showConvert == false) {
        this.showConvert = true;
      } else {
        this.$refs.form.validate().then(() => {
          this.show = true;
          this.$axios({
            url: "/island/web/exchange",
            method: "post",
            params: this.userInfo
          }).then((result) => {
            this.show = false;
            this.showConvert = false;
            if (result.data.code == 1) {
              this.showCenter = true;
            } else {
              this.showFail = true;
              this.showFailMsg = result.data.message;
            }
          });
        });
      }
    }
  },
  created() {
    this.getConvertCenter();
  }
};
</script>

<style lang="scss">
.taskCenterLayout {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/taskCenterBg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  .taskCenterBox {
    padding: 4%;
    .RadioStyle input {
      line-height: 30px;
      height: 40px;
      border-radius: 6px;
      display: none;
    }
    .RadioStyle label {
      border: 2px solid rgb(175, 175, 175);
      line-height: 40px;
      height: 40px;
      font-size: 14px;
      padding: 0px 20px;
      float: left;
      border-radius: 6px;
      margin: 5px 8px;
      text-align: center;
    }
    .RadioStyle input:checked + label {
      border: 2px solid rgba(139, 3, 249, 1);
      color: rgba(139, 3, 249, 1);
    }
  }
  .van-cell {
    padding: 2px 18px !important;
  }
  .van-field__label {
    color: rgba(136, 0, 252, 1);
  }
  .van-field__control {
    border: 1px solid rgba(220, 223, 235, 1);
    padding: 2px;
    border-radius: 6px;
  }
  .van-field__control:focus {
    border: 1px solid rgba(76, 56, 175, 1);
    padding: 2px;
    border-radius: 6px;
  }
}
</style>
