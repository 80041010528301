<template>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <van-loading size="50px" style="color: white;" vertical>
        <span style="font-size: 18px;color: white;">{{$t('common.i_loading')}}</span>
      </van-loading>
    </div>
  </van-overlay>
  <div class="taskCenterLayout">
    <div>
      <div style="">
        <div class="taskCenterBox" @click="$router.back(-1)" style="text-align: left;padding: 3% 4% 3% 4%;">
          <van-image width="30" :src="require('@/assets/images/goBack.png')" />
        </div>
      </div>
    </div>

    <div style="padding: 5%;background-color: white;">
      <div style="text-align: left;">
        <van-image style="vertical-align: middle;width: 18px;" :src="require('@/assets/images/cashLog.png')" />
        <span style="vertical-align: middle;margin-left: 6px;color: rgba(86, 137, 138, 1);">{{ $t('taskCenter.i_cashLog') }}</span>
      </div>

      <van-list style="overflow-y: scroll;height: 83vh;" v-model:loading="loading" :finished="finished" :finished-text="$t('convertCenter.i_noMore')" @load="onLoad">
        <div v-for="log in convertLog" :key="log" style="overflow: hidden;margin-top: 5%;">
          <div style="float: left;text-align: left;">
            <div style="line-height: 30px;">{{log.logDesc}}</div>
            <div style="color: rgba(124, 124, 139, 1);font-size: 12px;">{{log.createTime}}</div>
          </div>
          <div style="float: right;">
            <div style="color: rgba(139, 3, 249, 1);margin-top: 14px;">
              {{log.cash}}
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const convertLog = ref([]);
    const pageNum = ref(0);
    const isGo = ref(false);

    return {
      list,
      loading,
      finished,
      convertLog,
      pageNum,
      isGo
    };
  },
  methods: {
    onLoad() {
      this.pageNum++;
      this.$axios({
        url: "/island/web/cashLog",
        method: "get",
        params: {
          lang: this.$t("common.i_lang"),
          pageNum: this.pageNum
        }
      }).then((result) => {
        this.show = false;
        result.data.data.log.forEach((element) => {
          this.convertLog.push(element);
        });
        this.finished = result.data.data.isLast;
      });
      this.loading = false;
    },
    goApp() {
      location.href = "luckydynamicisland://";
    }
  },
  created() {}
};
</script>

<style lang="scss">
.taskCenterLayout {
  width: 100%;
  height: 100%;
  background-color: white;
  .taskCenterBox {
    background-image: url("@/assets/images/taskCenterBg.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
