<template>
  <div class="convertLogLayout">
    <div class="convertLogBox">
      <!-- <div style="text-align: left;">
        <van-image @click="goApp()" width="30" :src="require('@/assets/images/goBack.png')" />
      </div> -->
      <div style="margin-top: 2%;">
        <div style="background-color: white;padding: 3%;border-radius: 10px;height: 84vh;">
          <van-image style="width: 120px;margin-top: 50%;" :src="require('@/assets/images/tip.png')" />
          <div style="line-height: 30px;margin-top: 10%;">
            {{$t('errorPage.i_giftEnd')}}
          </div>
          <!-- <van-button @click="goApp()" type="primary" round style="width: 60%;margin-top: 18%;background-color: rgba(55, 87, 228, 1);" size="large">{{$t('errorPage.i_goNow')}}</van-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  setup() {},
  methods: {
    goApp() {
      location.href = "luckydynamicisland://";
    }
  }
};
</script>

<style lang="scss">
.convertLogLayout {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/taskCenterBg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  .convertLogBox {
    padding: 4%;
    .van-cell:after {
      border-bottom: 1px solid rgba(222, 222, 222, 1);
    }
  }
}
</style>
  