import { createI18n } from "vue-i18n";
// 引入各个语言配置文件
import zh from "./lang/zh";
import en from "./lang/en";
import tw from "./lang/tw";

var language = navigator.language;

if (
  language == "zh-CN" ||
  language == "zh-MO" ||
  language == "zh-SG" ||
  language == "zh" ||
  language == "en-US" ||
  language == "en"
) {
  language;
} else if (language == "zh-TW" || language == "zh-HK") {
  language = "tw";
} else {
  language = "en";
}

// 创建vue-i18n实例i18n
const i18n = createI18n({
  // 设置默认语言
  locale: language, // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    "zh-CN": zh,
    "en-US": en,
    "zh-TW": tw,
    "zh-HK": tw,
    "zh-MO": zh,
    "zh-SG": zh,
    zh: zh,
    en: en,
    tw: tw
  }
});
// 暴露i18n
export default i18n;
