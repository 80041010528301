import axios from "axios";
import router from "@/router";
import { showToast } from "vant";

let config = {
  //baseURL: "/api",
  //baseURL: "http://192.168.2.59:8020",
  baseURL: "https://cs.qlinksinc.com",
  timeout: 30 * 1000 // Timeout
  //withCredentials: true // Check cross-site Access-Control
};

const axiosService = axios.create(config);

axiosService.interceptors.request.use(
  function (config) {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosService.interceptors.response.use(
  function (response) {
    if (response.data.code == -1) {
      localStorage.removeItem("token");
      router.push("/errorPage");
    }
    return response;
  },
  function (error) {
    if (error.config.timeout) {
      const language = navigator.language;
      if (
        language == "zh-CN" ||
        language == "zh-MO" ||
        language == "zh-SG" ||
        language == "zh"
      ) {
        showToast({
          message: "请求超时，请检查网络",
          wordBreak: "break-word",
          duration: 0
        });
      } else if (language == "zh-TW" || language == "zh-HK") {
        showToast({
          message: "請求超時，請檢查網絡",
          wordBreak: "break-word",
          duration: 0
        });
      } else {
        showToast({
          message: "Request timed out, please check network",
          wordBreak: "break-word",
          duration: 0
        });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosService;
