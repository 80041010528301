const tw = {
  common: {
    i_loading: "加載中。。。",
    i_back: "返回",
    i_timedOut: "請求超時，請檢查網絡",
    i_ok: "好的",
    i_lang: "tw"
  },
  taskCenter: {
    i_myCash: "我的現金",
    i_record: "兌換記錄",
    i_withdrawTo: "提現到",
    i_completeTasksToClaimCash: "完成任務去領取現金",
    i_completed: "已完成",
    i_unfinished: "未完成",
    i_startTask: "開始任務",
    i_continueTomorrow: "明日繼續",
    i_claimReward: "領取獎勵",
    i_congratulations: "恭喜獲得",
    i_d: "第",
    i_t: "天",
    i_goNow: "立即前往",
    i_cashLog: "現金收益",
    i_tomorrowTask: "請明日再繼續任務！"
  },
  convertCenter: {
    i_reviewing: "稽核中",
    i_failed: "兌換失敗",
    i_remark: "備註",
    i_success: "兌換成功",
    i_invalid: "無效",
    i_noResponsibleForWrongAccount: "輸錯帳號概不負責",
    i_selectAmount: "選擇兌換金額",
    i_withdrawNow: "立即提現",
    i_underReviewPlease: "正在稽核中，請耐心等待",
    i_noMore: "沒有更多了"
  },
  errorPage: {
    i_errorMessage: "授權過期，請返回app重新打開該頁面",
    i_goNow: "立即返回",
    i_giftEnd: "該禮品活動已經結束！"
  },
  language: {
    zh: "中文",
    en: "English",
    tw: "繁体"
  }
};
export default tw;
