const en = {
  common: {
    i_loading: "Loading...",
    i_back: "Back",
    i_timedOut: "Request timed out, please check network",
    i_ok: "Ok",
    i_lang: "en"
  },
  taskCenter: {
    i_myCash: "My Cash",
    i_record: "Record",
    i_withdrawTo: "Withdraw to ",
    i_completeTasksToClaimCash: "Complete tasks to claim cash",
    i_completed: "Completed",
    i_unfinished: "Unfinished",
    i_startTask: "Start task",
    i_continueTomorrow: "Continue tomorrow",
    i_claimReward: "Claim",
    i_congratulations: "Congratulations",
    i_d: "Day ",
    i_t: "",
    i_goNow: "Go",
    i_cashLog: "Cash Log",
    i_tomorrowTask: "Please continue the task tomorrow !"
  },
  convertCenter: {
    i_reviewing: "Reviewing",
    i_failed: "Failed",
    i_remark: "Remark",
    i_success: "Success",
    i_invalid: "Invalid",
    i_noResponsibleForWrongAccount: "No responsible for wrong account",
    i_selectAmount: "Select amount",
    i_withdrawNow: "Withdraw now",
    i_underReviewPlease: "Under review, please be patient 7days",
    i_noMore: "No more"
  },
  errorPage: {
    i_errorMessage:
      "Authorization expired, please return to the app to reopen the page",
    i_goNow: "Go now",
    i_giftEnd: "The Gift event has ended !!!"
  },
  language: {
    zh: "中文",
    en: "English",
    tw: "繁体"
  }
};
export default en;
