import { createRouter, createWebHashHistory } from "vue-router";
import TaskCenter from "@/views/TaskCenter.vue";
import ConvertLog from "@/views/ConvertLog.vue";
import ConvertCenter from "@/views/ConvertCenter.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import TaskError from "@/views/TaskError.vue";
import TaskCenterVT from "@/views/TaskCenterVT.vue";
import CashLog from "@/views/CashLog.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: TaskCenter
  },
  {
    path: "/taskCenter",
    name: "taskCenter",
    component: TaskCenter
  },
  {
    path: "/taskCenterVT",
    name: "taskCenterVT",
    component: TaskCenterVT
  },
  {
    path: "/convertLog",
    name: "convertLog",
    component: ConvertLog
  },
  {
    path: "/convertCenter",
    name: "convertCenter",
    component: ConvertCenter
  },
  {
    path: "/errorPage",
    name: "errorPage",
    component: ErrorPage
  },
  {
    path: "/taskError",
    name: "taskError",
    component: TaskError
  },
  {
    path: "/cashLog",
    name: "cashLog",
    component: CashLog
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
