<template>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <van-loading size="50px" style="color: white;" vertical>
        <span style="font-size: 18px;color: white;">{{$t('common.i_loading')}}</span>
      </van-loading>
    </div>
  </van-overlay>
  <div class="taskCenterLayoutVT">
    <div class="taskCenterBoxVT">
      <div style="text-align: left;padding: 3% 4% 0 4%;">
        <van-image @click="goApp()" width="30" :src="require('@/assets/images/goBack.png')" />
      </div>
      <div style="margin-top: 3%;">
        <div style="background-color: white;padding: 5%;">
          <div style="overflow: hidden;">
            <div style="float: left;">
              <router-link to="/cashLog">
                <van-image style="vertical-align: middle;width: 18px;" :src="require('@/assets/images/cashLog.png')" />
                <span style="vertical-align: middle;margin-left: 6px;">{{$t('taskCenter.i_cashLog')}}</span>
              </router-link>
            </div>
            <div style="float: right;">
              <router-link to="/convertLog">
                <van-image style="vertical-align: middle;width: 18px;" :src="require('@/assets/images/convertLog.png')" />
                <span style="vertical-align: middle;margin-left: 6px;">{{$t('taskCenter.i_record')}}</span>
              </router-link>
            </div>
          </div>
          <div :class="{ 'apply-shake': shake }" v-if="task.userStatus == 0" style="color: rgba(139, 3, 249, 1);font-size: 30px;margin-top: 6%;margin-bottom: 6%;">
            {{task.userCash}} {{task.unit}}
          </div>
          <div v-else style="color: rgba(139, 3, 249, 1);font-size: 30px;margin-top: 6%;margin-bottom: 6%;">
            0
          </div>
          <div>
            <div style="height: 56px;">
              <van-image v-if="task.userStatus == 0" @click="goCashWithdrawal()" style="vertical-align: middle;width: 245px;position: relative;" :src="require('@/assets/images/cashBtn.png')">
                <van-image style="vertical-align: middle;width: 46px;position: absolute;top: 8px;left: 30px;" :src="task.giftIcon" />
                <span style="position: absolute;top: 14px;color: white;left: 84px;">{{$t('taskCenter.i_withdrawTo')}}{{task.giftName}}</span>
              </van-image>

              <router-link v-else to="/convertLog">
                <van-image style="vertical-align: middle;width: 245px;position: relative;" :src="require('@/assets/images/cashBtn.png')">
                  <van-image style="vertical-align: middle;width: 46px;position: absolute;top: 8px;left: 30px;" :src="task.giftIcon" />
                  <span style="position: absolute;top: 14px;color: white;left: 100px;">{{$t('taskCenter.i_record')}}</span>
                </van-image>
              </router-link>
            </div>
          </div>

          <div style="margin-top: 10%;">
            <van-image :src="require('@/assets/images/adImg.png')" />
          </div>

          <div v-if="task.userStatus == 0" style="margin-top: 4%;">
            <div :class="{ 'apply-shake': shake }" style="">
              <div style="margin: 3% auto;">
                <div v-for="task, index in taskList" :key="index">
                  <div v-if="task.status != 1" style="overflow: hidden;margin-top: 8%;margin-bottom: 8%;">
                    <div style="float: left;">
                      <van-image v-if="task.taskType == 2" style="width: 34px;vertical-align: middle;" :src="require('@/assets/images/loginTask.png')" />
                      <van-image v-if="task.taskType == 3" style="width: 34px;vertical-align: middle;" :src="require('@/assets/images/b1.png')" />
                    </div>
                    <div style="float: left;">
                      <div v-if="task.taskType == 2" style="margin-top: 6px;"></div>
                      <div v-html="task.title" style="white-space: pre-line;vertical-align: middle;display: inline;margin-left: 4px;"></div>
                      <div style="color: rgba(139, 3, 249, 1);margin-left: 8px;font-weight: bold;display: inline;">+{{ task.cash }} {{ task.unit }}</div>
                      <van-image style="width: 12px;vertical-align: middle;margin-left: 2px;" :src="require('@/assets/images/ok.png')" />
                      <div v-if="task.taskType == 3" style="text-align: left;margin-left: 4px;font-size: 10px;color: rgba(124, 124, 139, 1);">
                        {{$t('taskCenter.i_d')}}{{task.taskDays}}{{$t('taskCenter.i_t')}}:
                        (<span v-if="task.signNum != null">
                          {{task.taskNum}}
                        </span>
                        <span v-else>
                          <span v-if="task.taskDays == curDay + 1&&isCurDate ==false">{{turntableNum}}</span>
                          <span v-else-if="task.taskDays == curDay&&isCurDate ==true">{{turntableNum}}</span>
                          <span v-else>0</span>
                        </span>/{{task.taskNum}})
                      </div>
                    </div>
                    <div style="float: right;">
                      <div v-if="task.taskType == 2">
                        <van-button v-if="loginStatus < task.taskNum" @click="openAppLogin()" round style="background-color: rgba(239, 86, 138, 1);color: white;width: 100px;height: 36px;">
                          {{ $t('taskCenter.i_goNow') }}
                        </van-button>
                      </div>
                      <div v-if="task.taskType == 3">
                        <van-button v-if="task.signNum == null&&task.taskDays == curDay + 1&&isCurDate ==false" @click="openApp()" round style="background-color: rgba(239, 86, 138, 1);color: white;width: 100px;height: 36px;">
                          {{ $t('taskCenter.i_goNow') }}
                        </van-button>
                        <van-button v-if="task.signNum == null&&task.taskDays==curDay" @click="openApp()" round style="background-color: rgba(239, 86, 138, 1);color: white;width: 100px;height: 36px;">
                          {{ $t('taskCenter.i_goNow') }}
                        </van-button>
                        <van-button v-if="task.signNum == null&&task.taskDays + 1&&isCurDate ==true&&task.taskDays!=curDay" @click="showCenter = true" round style="background-color: rgba(185, 182, 198, 1);color: white;width: 100px;height: 36px;">
                          {{$t('taskCenter.i_d')}}{{task.taskDays}}{{$t('taskCenter.i_t')}}
                        </van-button>
                        <van-button v-if="task.signNum == null&&task.taskDays!=curDay + 1&&isCurDate ==false" @click="showCenter = true" round style="background-color: rgba(185, 182, 198, 1);color: white;width: 100px;height: 36px;">
                          {{$t('taskCenter.i_d')}}{{task.taskDays}}{{$t('taskCenter.i_t')}}
                        </van-button>
                      </div>

                      <div v-if="task.taskType == 2">
                        <van-button v-if="loginStatus == task.taskNum" @click="getReward(task.taskId)" round style="color: white;width: 100px;height: 36px;background-color: blueviolet;">
                          {{ $t('taskCenter.i_claimReward') }}
                        </van-button>
                      </div>
                      <div v-if="task.taskType == 3">
                        <van-button v-if="(turntableNum >= task.taskNum&&task.taskDays==curDay) || task.signNum != null" @click="getReward(task.taskId)" round style="color: white;width: 100px;height: 36px;background-color: blueviolet;">
                          {{ $t('taskCenter.i_claimReward') }}
                        </van-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <van-popup v-model:show="showCenter" round style="width: 200px;height: 180px;">
        <div style="margin-top: 50px;">{{$t('taskCenter.i_tomorrowTask')}}</div>
        <van-button @click="showPopup" round color="linear-gradient(to right, rgba(136, 0, 252, 1), rgba(255, 99, 121, 1))" style="width: 140px;height: 36px;margin-top: 30px;">
          {{$t('common.i_ok')}}
        </van-button>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const show = ref(false);
    const showCenter = ref(false);
    const showPopup = () => {
      showCenter.value = false;
    };
    const shake = ref(false);
    const task = ref({
      appId: "",
      uid: "",
      userCash: "",
      cash: "",
      unit: "",
      giftIcon: "",
      giftName: "",
      title: "",
      desc: "",
      taskType: "",
      taskDays: 0,
      taskNum: 0,
      turntableNum: 0,
      signNum: 0,
      status: 0,
      userStatus: 0
    });
    const taskList = ref([]);
    const turntableNum = ref(0);
    const loginStatus = ref(0);
    const isCurDate = ref(false);
    const curDay = ref(0);
    return {
      show,
      showCenter,
      showPopup,
      shake,
      task,
      taskList,
      turntableNum,
      loginStatus,
      isCurDate,
      curDay
    };
  },
  methods: {
    goCashWithdrawal() {
      if (this.task.userCash != "0" && this.task.userCash != "0.0") {
        this.$router.push("/convertCenter");
      } else {
        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 820); // 动画持续时间为0.82s
      }
    },
    getTask() {
      this.show = true;
      this.$axios({
        url: "/island/web/task",
        method: "get",
        params: {
          lang: this.$t("common.i_lang")
        }
      }).then((result) => {
        this.show = false;
        //console.log(result.data.data);
        this.turntableNum = result.data.data.turntableNum;
        this.loginStatus = result.data.data.loginStatus;
        this.isCurDate = result.data.data.isCurDate;
        this.curDay = result.data.data.curDay;
        this.task = result.data.data.task;
        this.taskList = result.data.data.taskList;
      });
    },
    openApp() {
      location.href = "luckydynamicisland://spin";
    },
    openAppLogin() {
      location.href = "luckydynamicisland://login";
    },
    goApp() {
      location.href = "luckydynamicisland://";
    },
    getReward(taskId) {
      //console.log(taskId);
      this.show = true;
      this.$axios({
        url: "/island/web/getReward",
        method: "get",
        params: {
          taskId: taskId
        }
      }).then((result) => {
        this.show = false;
        console.log(result.data.data);
        this.getTask();
      });
    }
  },
  created() {
    if (this.$route.query.token == null) {
      this.$router.push("/errorPage");
    } else {
      localStorage.setItem("token", this.$route.query.token);
      this.getTask();
    }
  }
};
</script>

<style lang="scss">
.taskCenterLayoutVT {
  width: 100%;
  height: 100%;
  background-color: white;
  .taskCenterBoxVT {
    background-image: url("@/assets/images/taskCenterBg.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
