const zh = {
  common: {
    i_loading: "加载中。。。",
    i_back: "返回",
    i_timedOut: "请求超时，请检查网络",
    i_ok: "好的",
    i_lang: "zh"
  },
  taskCenter: {
    i_myCash: "我的现金",
    i_record: "兑换记录",
    i_withdrawTo: "提现到",
    i_completeTasksToClaimCash: "完成任务去领取现金",
    i_completed: "已完成",
    i_unfinished: "未完成",
    i_startTask: "开始任务",
    i_continueTomorrow: "明日继续",
    i_claimReward: "领取奖励",
    i_congratulations: "恭喜获得",
    i_d: "第",
    i_t: "天",
    i_goNow: "立即前往",
    i_cashLog: "现金收益",
    i_tomorrowTask: "请明日再继续任务！"
  },
  convertCenter: {
    i_reviewing: "审核中",
    i_failed: "兑换失败",
    i_remark: "备注",
    i_success: "兑换成功",
    i_invalid: "无效",
    i_noResponsibleForWrongAccount: "输错账号概不负责",
    i_selectAmount: "选择兑换金额",
    i_withdrawNow: "立即提现",
    i_underReviewPlease: "正在审核中，请耐心等待",
    i_noMore: "没有更多了"
  },
  errorPage: {
    i_errorMessage: "授权过期，请返回app重新打开该页面",
    i_goNow: "立即返回",
    i_giftEnd: "该礼品活动已经结束!"
  },
  language: {
    zh: "中文",
    en: "English",
    tw: "繁体"
  }
};
export default zh;
