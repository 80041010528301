<template>
  <div class="convertLogLayout">
    <div class="convertLogBox">
      <div style="text-align: left;">
        <van-image v-if="isGo == false" @click="$router.back(-1)" width="30" :src="require('@/assets/images/goBack.png')" />
        <van-image v-if="isGo == true" @click="goApp()" width="30" :src="require('@/assets/images/goBack.png')" />
      </div>
      <div style="margin-top: 2%;">
        <div style="background-color: white;padding: 3%;border-radius: 10px;">
          <van-list style="overflow-y: scroll;height: 87vh;" v-model:loading="loading" :finished="finished" :finished-text="$t('convertCenter.i_noMore')" @load="onLoad">
            <van-cell center v-for="log in convertLog" :key="log">
              <div style="overflow: hidden;">
                <div style="float: left;color: black;text-align: left;">
                  <span>{{log.name}}</span>
                  <br />
                  <span style="color: black;font-weight: bold;">+{{log.cash}}</span>
                </div>
                <div style="float: right;">
                  <span>{{log.date}}</span>
                  <br />
                  <span v-if="log.status == 0" style="color: rgba(37, 88, 213, 1);">{{$t('convertCenter.i_reviewing')}}</span>
                  <span v-if="log.status == 1" style="color: rgba(92, 94, 104, 1);"><span style="color: blue;">✔</span>{{$t('convertCenter.i_success')}}</span>
                  <span v-if="log.status == 2" style="color: rgba(244, 81, 49, 1);">{{$t('convertCenter.i_failed')}}</span>
                  <span v-if="log.status == 3" style="color: rgba(244, 81, 49, 1);">{{$t('convertCenter.i_invalid')}}</span>
                </div>
              </div>
              <div v-if="log.status == 2||log.status == 3" style="text-align: left;color: red;">{{$t('convertCenter.i_remark')}}：{{log.remarks}}</div>
            </van-cell>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const convertLog = ref([]);
    const pageNum = ref(0);
    const isGo = ref(false);

    return {
      list,
      loading,
      finished,
      convertLog,
      pageNum,
      isGo
    };
  },
  methods: {
    onLoad() {
      this.pageNum++;
      this.$axios({
        url: "/island/web/convertLog",
        method: "get",
        params: {
          lang: this.$t("common.i_lang"),
          pageNum: this.pageNum
        }
      }).then((result) => {
        this.show = false;
        result.data.data.log.forEach((element) => {
          this.convertLog.push(element);
        });
        this.finished = result.data.data.isLast;
      });
      this.loading = false;
    },
    goApp() {
      location.href = "luckydynamicisland://";
    }
  },
  created() {
    if (this.$route.query.token != null) {
      this.isGo = true;
      localStorage.setItem("token", this.$route.query.token);
    }
  }
};
</script>

<style lang="scss">
.convertLogLayout {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/taskCenterBg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  .convertLogBox {
    padding: 4%;
    .van-cell:after {
      border-bottom: 1px solid rgba(222, 222, 222, 1);
    }
  }
}
</style>
