import "babel-polyfill"; //放到第一行！！！务必
import promise from "es6-promise";
promise.polyfill();
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vant from "vant";
import "vant/lib/index.css";

import i18n from "./i18n/index";
import axios from "./api/axios";
import VueAxios from "vue-axios";

const app = createApp(App);
app.use(store);
app.use(router);

app.use(vant);

app.use(i18n);
app.config.globalProperties.$axios = axios;
app.use(VueAxios, axios);

app.mount("#app");
