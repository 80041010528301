<template>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <van-loading size="50px" style="color: white;" vertical>
        <span style="font-size: 18px;color: white;">{{$t('common.i_loading')}}</span>
      </van-loading>
    </div>
  </van-overlay>
  <div class="taskCenterLayout">
    <div class="taskCenterBox">
      <!-- <div style="text-align: left;">
        <van-image width="30" :src="require('@/assets/images/goBack.png')" />
      </div> -->
      <div style="margin-top: 2%;">
        <div style="background-color: white;padding: 3%;border-radius: 10px;">
          <div style="overflow: hidden;">
            <div style="float: left;">
              <van-image style="vertical-align: middle;width: 30px;" :src="require('@/assets/images/myMoney.png')" />
              <span style="vertical-align: middle;margin-left: 6px;">{{$t('taskCenter.i_myCash')}}</span>
            </div>
            <div style="float: right;">
              <router-link to="/convertLog">
                <van-image style="vertical-align: middle;width: 24px;" :src="require('@/assets/images/convertLog.png')" />
                <span style="vertical-align: middle;margin-left: 6px;">{{$t('taskCenter.i_record')}}</span>
              </router-link>
            </div>
          </div>
          <div v-if="task.userStatus == 0" style="color: rgba(139, 3, 249, 1);font-size: 30px;margin-top: 6%;margin-bottom: 6%;">
            {{task.cash}} {{task.unit}}
          </div>
          <div v-else style="color: rgba(139, 3, 249, 1);font-size: 30px;margin-top: 6%;margin-bottom: 6%;">
            0
          </div>
          <div>
            <div style="height: 56px;">
              <van-image v-if="task.userStatus == 0" @click="goCashWithdrawal()" style="vertical-align: middle;width: 245px;position: relative;" :src="require('@/assets/images/cashBtn.png')">
                <van-image style="vertical-align: middle;width: 46px;position: absolute;top: 8px;left: 30px;" :src="task.giftIcon" />
                <span style="position: absolute;top: 14px;color: white;left: 84px;">{{$t('taskCenter.i_withdrawTo')}}{{task.giftName}}</span>
              </van-image>

              <router-link v-else to="/convertLog">
                <van-image style="vertical-align: middle;width: 245px;position: relative;" :src="require('@/assets/images/cashBtn.png')">
                  <van-image style="vertical-align: middle;width: 46px;position: absolute;top: 8px;left: 30px;" :src="task.giftIcon" />
                  <span style="position: absolute;top: 14px;color: white;left: 100px;">{{$t('taskCenter.i_record')}}</span>
                </van-image>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="task.userStatus == 0" style="margin-top: 4%;">
        <div :class="{ 'apply-shake': shake }" style="background-color: white;padding: 3%;border-radius: 10px;">
          <div style="text-align: left;font-size: 18px;">
            {{$t('taskCenter.i_completeTasksToClaimCash')}}
          </div>
          <div style="margin: 3% auto;">
            <div style="overflow: hidden;">
              <div v-html="task.title" style="float: left;white-space: pre-line;"></div>
              <div v-if="task.signNum >= task.taskDays" style="float: right;">{{$t('taskCenter.i_completed')}}</div>
              <div v-else style="float: right;color: rgba(239, 86, 138, 1);">{{$t('taskCenter.i_unfinished')}}</div>
            </div>
            <div v-html="task.desc" style="white-space: pre-line;text-align: left;margin: 3% auto;font-size: 14px;"></div>
            <div style="text-align: left;margin: 3% auto;color: rgba(139, 3, 249, 1);font-size: 20px;">
              {{task.signNum < task.taskDays?((task.turntableNum == task.taskNum?0:task.turntableNum)+task.taskNum*task.signNum):task.taskNum*task.signNum}}/{{task.taskNum*task.taskDays}}
            </div>
            <div style="margin-bottom: 10%;overflow: hidden;">
              <div v-for="index of task.taskDays" :key="index" style="float: left;">
                <div style="margin: 6px;">
                  <div v-if="index <= task.signNum" style="background-color: rgba(133, 98, 235, 1);width: 60px;height: 60px;border-radius: 8px;">
                    <van-image style="width: 30px;margin-top: 6px;" :src="require('@/assets/images/b1.png')" />
                    <div style="color: white;line-height: 14px;font-size: 14px;">✔</div>
                  </div>
                  <div v-else style="background-color: rgba(241, 241, 241, 1);width: 60px;height: 60px;border-radius: 8px;">
                    <van-image style="width: 30px;margin-top: 6px;" :src="require('@/assets/images/b2.png')" />
                    <div style="color: white;line-height: 14px;font-size: 14px;color: #000;">{{$t('taskCenter.i_d')}}{{index}}{{$t('taskCenter.i_t')}}</div>
                  </div>
                  <div v-if="index <= task.signNum">{{task.taskNum}}/{{task.taskNum}}</div>
                  <div v-if="index == (task.signNum == 0 ? 1 :task.signNum + 1) && task.turntableNum < task.taskNum">{{task.turntableNum}}/{{task.taskNum}}</div>
                  <div v-if="index > (task.turntableNum >= task.taskNum ? task.signNum: task.signNum+1)">0/{{task.taskNum}}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <van-button @click="openApp()" v-if="task.signNum == 0 || (task.turntableNum < task.taskNum && task.signNum < task.taskDays)" round style="background-color: rgba(239, 86, 138, 1);color: white;width: 60%;height: 38px;">{{$t('taskCenter.i_startTask')}}</van-button>
            <van-button v-if="task.signNum < task.taskDays && task.signNum != 0 && task.turntableNum >= task.taskNum" round style="background-color: rgba(178, 173, 194, 1);color: white;width: 60%;height: 38px;">{{$t('taskCenter.i_continueTomorrow')}}</van-button>
            <van-button @click="goCashWithdrawal()" v-if="task.signNum >= task.taskDays" round style="background-color: rgba(136, 0, 252, 1);color: white;width: 60%;height: 38px;">{{$t('taskCenter.i_claimReward')}}</van-button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <van-popup v-model:show="showCenter" round style="width: 240px;height: 250px;">
        <div style="margin-top: 40px;">{{$t('taskCenter.i_congratulations')}}</div>
        <div style="margin-top: 30px;font-size: 22px;color: rgba(55, 87, 228, 1);">10php</div>
        <van-button @click="showPopup" round color="linear-gradient(to right, rgba(136, 0, 252, 1), rgba(255, 99, 121, 1))" style="width: 160px;height: 36px;margin-top: 50px;">
          {{$t('common.i_ok')}}
        </van-button>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const show = ref(false);
    const showCenter = ref(false);
    const showPopup = () => {
      showCenter.value = false;
    };
    const shake = ref(false);
    const task = ref({
      appId: "",
      uid: "",
      userCash: "",
      cash: "",
      unit: "",
      giftIcon: "",
      giftName: "",
      title: "",
      desc: "",
      taskType: "",
      taskDays: 0,
      taskNum: 0,
      turntableNum: 0,
      signNum: 0,
      status: 0,
      userStatus: 0
    });
    return {
      show,
      showCenter,
      showPopup,
      shake,
      task
    };
  },
  methods: {
    goCashWithdrawal() {
      if (this.task.signNum >= this.task.taskDays) {
        this.$router.push("/convertCenter");
      } else {
        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 820); // 动画持续时间为0.82s
      }
    },
    getTask() {
      this.show = true;
      this.$axios({
        url: "/island/web/task",
        method: "get",
        params: {
          lang: this.$t("common.i_lang")
        }
      }).then((result) => {
        this.show = false;
        this.task = result.data.data.task;
        if (this.task.status == 2) {
          this.$router.push("/taskError");
        }
        if (this.task == null) {
          this.$router.push("/errorPage");
        }
      });
    },
    openApp() {
      location.href = "luckydynamicisland://spin";
    }
  },
  created() {
    if (this.$route.query.token == null) {
      this.$router.push("/errorPage");
    } else {
      localStorage.setItem("token", this.$route.query.token);
      this.getTask();
    }
  }
};
</script>

<style lang="scss">
.taskCenterLayout {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/taskCenterBg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  .taskCenterBox {
    padding: 4%;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
